/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
// import { Link } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import object, {
    func, array, bool, string, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import { handleOnClick } from '../../utils/pathUtils';
import replaceSpecialChar from '../../../../helpers/replaceSpecialChar';

const styles = () => ({
    bot: {
        display: 'none',
    },
    link: {
        '&:hover': {
            opacity: '.9',
        },
    },
    hlist: {
        alignSelf: 'stretch',
        display: 'flex',
        '@media screen and (max-width: 1024px)': {
            padding: '5px !important',
        },
    },
    hlink: {
        padding: '8px 14px',
        cursor: 'pointer',
        width: 'auto',
        minWidth: '120px',
        maxWidth: '190px',
        alignItems: 'center',
        display: 'flex',
        '@media screen and (max-width: 1024px)': {
            padding: '5px !important',
        },
    },
    hcopy: {
        height: 'auto',
        width: '100%',
        textAlign: 'center',
    },
    vlink: {
        zIndex: '1',
        textAlign: 'left',
        cursor: 'pointer',
    },
    vlist: {
        display: 'block',
        width: '300px',
        margin: 'auto auto auto 0',
        textAlign: 'left',
        cursor: 'pointer',
    },
    divideTwoColumn: {
        columnCount: 2,
    },
    listLink: {
        display: 'block',
    },
});

function LinkListContainer(props) {
    const {
        data, history, classes, trackEvent, isBot, uid, type,
    } = props;

    if (type === 'footermenu') return null;

    const linkData = data?.link_list?.reference?.[0] || data?.reference?.[0] || data;
    if (!linkData) return null;

    const links = linkData.links;
    if (!links) return null;

    const listDivideTwoColumn =  linkData?.is_column_present || false;
    const link_styles = linkData.styles;
    const mainStyle = generateValidCss(linkData.styles, true);
    const renderAsButtons = linkData.presentation === 'Buttons';
    const childFont = linkData.child_font?.replace(/font-family: |;/g, ''); // regex to match and remove text "font-family:"

    if (linkData.orientation === 'Vertical') {
        mainStyle.zIndex = '1';
        mainStyle.width = 'fit-content';
        mainStyle.maxWidth = listDivideTwoColumn ? 500 : 400;
        mainStyle.minWidth = '200px';
        if (!mainStyle.textAlign) { mainStyle.textAlign = 'left'; }
        if (!mainStyle.fontSize) { mainStyle.fontSize = '1.2em'; }
        if (!mainStyle.padding) { mainStyle.padding = '.5em 1em'; }
        mainStyle.boxSizing = 'border-box';
        if (renderAsButtons) {
            if (!mainStyle.margin) {
                mainStyle.marginLeft = 'auto';
                mainStyle.marginRight = 'auto';
            }
        }
    } else {
        mainStyle.zIndex = '1';
        if (!mainStyle.textAlign) { mainStyle.textAlign = 'center'; }
        if (!mainStyle.fontSize) { mainStyle.fontSize = '1.2em'; }
        if (!mainStyle.padding) { mainStyle.padding = '.5em 1em'; }
        mainStyle.width = '100%';
        mainStyle.boxSizing = 'border-box';
        mainStyle.background = 'transparent';
    }
    const getVerticalLinkStyle = (child, i) => {
        const linkStyle = generateValidCss(link_styles, true);
        linkStyle.cursor = 'pointer';
        linkStyle.padding = '.1em .3em';
        linkStyle.textDecoration = 'none';
        linkStyle.border = '0';
        if (renderAsButtons) {
            linkStyle.fontSize = '1em';
            linkStyle.fontWeight = '400';
            if (i) {
                linkStyle.marginTop = '.5em';
            }
            return linkStyle;
        }
        if (child) {
            linkStyle.fontSize = '.85em';
            linkStyle.textIndent = '1em';
            linkStyle.fontWeight = '400';
            linkStyle.marginLeft = '0';
            if (childFont) {
                linkStyle['font-family'] = childFont;
            }
        } else {
            linkStyle.fontSize = '1em';
            linkStyle.fontWeight = '400';
            if (i > 0) {
                linkStyle.borderTop = !listDivideTwoColumn && 'solid 1px #ccc';
            }
            linkStyle.marginLeft = '0';
        }
        return linkStyle;
    };
    const getHorizontalLinkStyle = () => {
        const linkStyle = generateValidCss(linkData.link_styles, true);
        linkStyle.padding = '.3em .8em';
        linkStyle.border = '0';
        linkStyle.color = mainStyle.color;
        linkStyle.backgroundColor = mainStyle['background-color'];
        return linkStyle;
    };

    const getTabIndex = (k) => parseInt(k, 10) + 1;

    // Check to see if we want to render a Horizontal Menu
    if (linkData.orientation === 'Horizontal') {
        return (
            <Grid container className="linkList" spacing={4} justify="center" alignItems="center" style={mainStyle} data-uniqueid={uid}>
                {Object.entries(links).map(([key, value]) => value?.link && (
                    <>
                        {isBot && (
                            <Link
                                className={classes.bot}
                                to={value?.link?.link?.href}
                                title={value?.link?.link?.title}
                            > {replaceSpecialChar(value?.link?.link?.title, true)}
                            </Link>
                        )}
                        <Grid item key={key} className={classes.hlist}>
                            <div
                                className={classes.hlink}
                                style={getHorizontalLinkStyle()}
                                title={value?.link?.link?.title}
                                spacing={4}
                                tabIndex={getTabIndex(key)}
                                role="link"
                                onClick={(e) => {
                                    trackEvent({
                                        eventCategory: value?.link?.tracking_event_category || '',
                                        eventAction: value?.link?.tracking_event_action || '',
                                        eventLabel: value?.link?.tracking_event_label || '',
                                    });
                                    handleOnClick(e, value?.link?.link?.href, history);
                                }}
                            >
                                <div className={classes.hcopy}>{replaceSpecialChar(value?.link?.link?.title, true)}</div>
                            </div>
                        </Grid>
                    </>
                ))}
            </Grid>
        );
    }

    return ( // Render Vertical Menus
        <div style={mainStyle} data-testid="divide-two-column" className={listDivideTwoColumn ? classes.divideTwoColumn : classes.linkList} tabIndex={getTabIndex(-1)} data-uniqueid={uid}>
            {linkData.heading && <h3>{linkData.heading}</h3>}
            {Object.entries(links).map(([key, value]) =>  value?.link && (
                <>
                    {isBot && (
                        <Link
                            className={classes.bot}
                            to={value?.link?.link?.href}
                            title={value?.link?.link?.title}
                        > {replaceSpecialChar(value?.link?.link?.title, true)}
                        </Link>
                    )}
                    {renderAsButtons ? (
                        <>
                            {!value?.link?.hide && (
                                <a
                                    href={value?.link?.link?.href}
                                    key={key}
                                    className={styles?.link}
                                    title={value?.link?.link?.title}
                                    style={getVerticalLinkStyle(value?.link?.is_child, key)}
                                    data-ga-category={value?.link?.tracking_event_category || ''}
                                    data-ga-action={value?.link?.tracking_event_action || ''}
                                    data-ga-label={value?.link?.tracking_event_label || ''}
                                    onClick={() => {
                                        trackEvent({
                                            eventCategory: value?.link?.tracking_event_category || '',
                                            eventAction: value?.link?.tracking_event_action || '',
                                            eventLabel: value?.link?.tracking_event_label || '',
                                        });
                                    }}
                                >
                                    {replaceSpecialChar(value?.link?.link?.title, true)}
                                </a>
                            )}
                        </>

                    )
                        : (
                            <>
                                {!value?.link?.hide && (
                                    <div
                                        key={key}
                                        role="button"
                                        to={value?.link?.link?.href}
                                        className={`${classes?.link} ${classes.listLink}`}
                                        title={value?.link?.link?.title}
                                        style={getVerticalLinkStyle(value?.link?.is_child, key)}
                                        tabIndex={getTabIndex(key)}
                                        data-ga-category={value?.link?.tracking_event_category || ''}
                                        data-ga-action={value?.link?.tracking_event_action || ''}
                                        data-ga-label={value?.link?.tracking_event_label || ''}
                                        onClick={(e) => {
                                            trackEvent({
                                                eventCategory: value?.link?.tracking_event_category || '',
                                                eventAction: value?.link?.tracking_event_action || '',
                                                eventLabel: value?.link?.tracking_event_label || '',
                                            });
                                            handleOnClick(e, value?.link?.link?.href, history);
                                        }}
                                    >
                                        {replaceSpecialChar(value?.link?.link?.title, true)}
                                    </div>
                                )}
                            </>
                        )}
                </>
            ))}
        </div>
    );
}

LinkListContainer.propTypes = {
    classes: object.isRequired,
    data: shape({
        links: shape({
            link: shape({
                add_icon: bool,
                is_child: bool,
                hide: bool,
                tracking_event_action: string,
                tracking_event_category: string,
                tracking_event_label: string,
                link: shape({
                    href: string,
                    title: string,
                }).isRequired,
            }),
        }),
        orientation: 'string',
        presentation: 'string',
        styles: array,
    }).isRequired,
    history: object,
    trackEvent: func.isRequired,
    isBot: bool,
    uid: string,
    type: string,
};
LinkListContainer.defaultProps = {
    isBot: false,
    uid: false,
    history: {},
    type: '',
};
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(withRouter(LinkListContainer)));
