/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { object, bool, string } from 'prop-types';
import { compose } from 'recompose';
import LinkListDesktop from './Desktop/LinkListDesktop';
import LinkListMobile from './Mobile/LinkListMobile';
import { getIsBot, getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';

function LinkList({
    data: blockData, type, isBot, ssrDeviceType,
}) {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
    });
    if (loading && ssrDeviceType?.toLowerCase() === 'mobile' && blockData?.mobile_skeleton) {
        return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
    }
    if (loading && ssrDeviceType?.toLowerCase() !== 'mobile' && blockData?.desktop_skeleton) {
        return <GenericSkeleton height={blockData?.skeleton_height} />;
    }
    let linkListData = null;
    if (data.link_list?.reference?.[0]) {
        linkListData = data.link_list?.reference?.[0];
    } else if (data.reference?.[0]?.links) {
        linkListData = data.reference?.[0];
    }

    if (!linkListData) return <></>;
    const { unique_selector = null } = linkListData;
    const device = ssrDeviceType?.toLowerCase();
    return (
        device === 'mobile'
            ? <LinkListMobile data={data} type={type} isBot={isBot} uid={uniqueId(unique_selector)} />
            : <LinkListDesktop data={data} type={type} isBot={isBot} uid={uniqueId(unique_selector)} />
    );
}

LinkList.propTypes = {
    isBot: bool,
    data: object.isRequired,
    type: object,
    ssrDeviceType: string.isRequired,
};

LinkList.defaultProps = {
    isBot: false,
    type: '',
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
    ssrDeviceType: getSSRDeviceType(state),
});
const enhance = compose(
    connect(mapStateToProps),
);
export default enhance(LinkList);
