/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, bool, shape, string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const styles = () => ({
    toggle_image: {
        width: '100%',
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 7px #bbb',
        },
    },
    modal_toggle: {
        backgroundColor: 'unset',
        border: '0',
        padding: '0',
        width: '100%',
        cursor: 'pointer',
        color: 'rgba(247, 238, 215, .8);',
        '&:hover': {
            color: 'rgba(247, 238, 215, 1);',
        },
    },
    modal_toggle_with_icon: {
        backgroundColor: 'unset',
        border: '0',
        padding: '0',
        width: '100%',
        cursor: 'pointer',
        '&::after': {
            content: '"\u25B6"',
            position: 'absolute',
            color: 'inherit',
            top: '35%',
            left: '0',
            right: '0',
            margin: '0 auto',
            width: '80px',
            height: '80px',
            border: 'solid 7px',
            borderRadius: '50%',
            fontWeight: '700',
            fontSize: '60px',
            textShadow: '1px 1px 2px #2f2f2f',
            paddingLeft: '12px',
        },
        color: 'rgba(247, 238, 215, .8);',
        '&:hover': {
            color: 'rgba(247, 238, 215, 1);',
        },
    },
    closeButton: {
        marginRight: '8px',
        marginLeft: 'auto',
    },
    modal_content: {
        overflow: 'hidden',
        height: '50vw',
        padding: '0 3% 3%',
    },
});

export function VideoModal({
    classes, children, image, showPlayIcon, imageDimensions,
}) {
    const [open, setOpen] = React.useState(false);

    const stopPropagation = (e) => {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
    };
    const handleOpen = (e) => {
        setOpen(true);
        stopPropagation(e);
    };
    const handleClose = (e) => {
        setOpen(false);
        stopPropagation(e);
    };
    return (
        <div>
            <button type="button" onClick={(e) => { handleOpen(e); }} className={showPlayIcon ? classes.modal_toggle_with_icon : classes.modal_toggle}>
                {/* used to have class toggleImage */}
                <ResponsiveImage
                    path={image?.url || ''}
                    alt={image?.description || 'Background Image'}
                    className={classes.toggle_image}
                    params={{ desktop: 'quality=100', tablet: 'quality=100', mobile: 'quality=100' }}
                    style={{ width: `${imageDimensions.width ||  'fit-content'}`, height: `${imageDimensions.width ||  'auto'}` }}
                />
            </button>
            <Dialog
                open={open}
                onClose={(e) => { handleClose(e); }}
                fullWidth
                maxWidth="lg"
                height="100%"
                data-testid="renderModal"
            >
                <IconButton data-testid="close-button" className={classes.closeButton} aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <MuiDialogContent className={classes.modal_content}>
                    {children}
                </MuiDialogContent>
            </Dialog>
        </div>
    );
}

VideoModal.propTypes = {
    classes: object.isRequired,
    children: object.isRequired,
    image: object.isRequired,
    showPlayIcon: bool,
    imageDimensions: shape({
        width: string,
        height: string,
    }),
};

VideoModal.defaultProps = {
    showPlayIcon: true,
    imageDimensions: {},
};

export default withStyles(styles)(VideoModal);
