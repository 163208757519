/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withRouter } from 'react-router';
import { object } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Media from 'react-media';
import Copy from './Copy';
import generateValidCss from '../../helpers/contentstack/generateValidCss';
import { trackEvent } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import VideoModalBlock from './Video/Partials/VideoModal';
import { handleOnClick } from './utils/pathUtils';

const useStyles = makeStyles(() => ({
    frame_style: {
        width: '100%',
        height: '100%',
    },
}));

const DotWhack = ({
    data, history,
}) => {
    if (data) {
        const whackStylesList = [{ dimensions: data.dimensions }];
        const getWhackStyles = whackStylesList[0].dimensions ? generateValidCss(whackStylesList, true) : '';
        const getMessageData = data.message_collection;

        const whackIsVidoModalButton = data.youtube_modal?.href?.length > 0;
        const whackIsLink = data.link?.url;
        const isBasicDotWhack = !whackIsVidoModalButton && !whackIsLink;

        const whackLinkIsExternal = data.link?.url && (data.link.url.includes('https://') || data.link.url.includes('http://'));
        const whackLinkIsInternal = !whackLinkIsExternal;

        const classes = useStyles();

        let videoId = data.youtube_modal?.href;
        if (data.youtube_modal?.href && data.youtube_modal.href.includes('http')) {
            const href = data.youtube_modal.href;
            videoId = href.substring(href.search('v=') + 2);
        }
        const src = `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&controls=1`;

        const trackableLinkHandler = (e, link) => {
            trackEvent({
                eventCategory: link.tracking_event_category || '',
                eventAction: link.tracking_event_action || '',
                eventLabel: link.tracking_event_label || '',
            });
            handleOnClick(e, link?.url, history);
        };
        const externalLinkHandler = (e) => {
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();
        };

        const basicWhackImageMarkup = (
            <img
                alt={data.file?.description || 'Image Embellishment'}
                src={data.file?.url}
                style={{
                    width: `${getWhackStyles.width === 'auto' ? 'fit-content' : getWhackStyles.width}`,
                    height: `${getWhackStyles.height}`,
                }}
            />
        );

        const whackVideoMarkup = (
            <VideoModalBlock image={data.file} showPlayIcon={false} imageDimensions={getWhackStyles}>
                <iframe
                    title="video"
                    className={classes.frame_style}
                    src={src}
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="1"
                />
            </VideoModalBlock>
        );

        const whackLinkMarkup = (link) => {
            if (whackLinkIsInternal && data.file) {
                return (
                    <div
                        style={{ zIndex: 2 }}
                        tabIndex="0"
                        role="button"
                        data-testid="whack-internal-link"
                        onClick={(e) => { trackableLinkHandler(e, data.link); }}
                        onKeyDown={(e) => { trackableLinkHandler(e, data.link); }}
                    >
                        <img
                            alt={data.file?.title}
                            src={data.file?.url}
                            style={{
                                width: `${getWhackStyles.width === 'auto' ? 'fit-content' : getWhackStyles.width}`,
                                height: `${getWhackStyles.height}`,
                            }}
                        />
                    </div>
                );
            }
            if (whackLinkIsExternal && data.file) {
                return (
                    <a href={link.url} data-testid="extLink" target="_blank" rel="noreferrer" onClick={(e) => { externalLinkHandler(e); }}>
                        <img
                            alt={data.file?.title}
                            src={data.file?.url}
                            style={{
                                width: `${getWhackStyles.width === 'auto' ? 'fit-content' : getWhackStyles.width}`,
                                height: `${getWhackStyles.height}`,
                            }}
                        />
                    </a>
                );
            }
            return <></>;
        };
        return (
            <Media
                queries={{
                    small: '(max-width: 600px)',
                    medium: '(min-width: 601px) and (max-width: 1024px)',
                    large: '(min-width: 1025px)',
                }}
            >
                {(matches) => (
                    ((matches.small && data.show_on_mobile) || (matches.medium && data.show_on_tablet) || matches.large)
                    && (
                        <div
                            className={classes.root}
                            style={{
                                position: 'absolute',
                                left: `${data.offset_left}em`,
                                top: `${data.offset_top}em`,

                            }}
                        >
                            {whackIsLink && whackLinkMarkup(data.link)}
                            {whackIsVidoModalButton && whackVideoMarkup}
                            {isBasicDotWhack && basicWhackImageMarkup}
                            <Copy data={getMessageData} />
                        </div>
                    )
                )}
            </Media>
        );
    }
    return <></>;
};

DotWhack.propTypes = {
    data: object.isRequired,
    history: object,
};
DotWhack.defaultProps = {
    history: {},
};

export default withRouter(DotWhack);
