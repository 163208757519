/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Render a Panel content type
//
// Most likely, your object has a reference to a panel that was resolved.
// e.g. left_panel_ref.  Pass the first element in that ref.  E.g. <Panel data={left_panel_ref[0]} />

import React from 'react';
import { object, array } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import Copy from './Copy';
import DotWhack from './DotWhack';
import LinkList from './LinkList/LinkList';
import generateValidCss from '../../helpers/contentstack/generateValidCss';

const useStyles = () => ({
    relative: {
        position: 'relative',
    },
});

function sanitizeCSS(s) {
    return s.replace(/[^-_.a-zA-Z0-9]/g, '');
}

function Panel2(data) {
    const returnValue = [];
    if (!Array.isArray(data)) {
        return [];
    }
    if (data.message_collection && Array.isArray(data.message_collection)) {
        data.message_collection.forEach((item) => {
            const k = Object.keys(item)[0];
            switch (k) {
                case 'copy':
                    returnValue.push(<Copy block={item.copy} />);
                    break;
                default:
                    mbpLogger.logWarning({ appName: process.env.npm_package_name, message: `Unhandled Panel component: ${sanitizeCSS(k)}` });
            }
        });
    }

    return returnValue;
}

Panel2.propTypes = {
    data: object.isRequired,
};

const Panel = ({
    classes,
    data,
    styleData,
}) => {
    if (data) {
        const hasStyles = styleData !== undefined && styleData.length > 0 ? generateValidCss(styleData, true) : {};
        const position = styleData?.position !== undefined && styleData?.position;
        const linkListIndex = () => {
            const messageBlock = data.message_collection || [];
            let i;
            let index = -1;
            for (i = 0; i < messageBlock.length; i += 1) {
                if ('link_list' in messageBlock[i]) {
                    index = i;
                }
            }
            return index;
        };

        let linkList;
        const linkListIndexOf = linkListIndex();
        if (linkListIndexOf !== -1) {
            linkList = <LinkList data={data.message_collection?.[linkListIndexOf]} />;
        }

        const overlays = data.overlays || [];

        return (
            <div style={{ position }}>
                <div style={hasStyles} className={classes.relative}>
                    {
                        // TODO: expand to include sashes and other overlays
                        overlays && overlays.length ? (
                            overlays.map((overlay) => (
                                overlay.whack && <DotWhack data={overlay.whack} />
                            ))
                        ) : null
                    }
                    {data?.message_collection && <Copy data={data?.message_collection} mobileCopy={data?.isMobileCopy || false} svgPositionStyles={data?.svgPositionStyles} />}
                </div>
                {linkList}
            </div>
        );
    }
    return <></>;
};

Panel.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    styleData: array,
};
Panel.defaultProps = {
    styleData: [],
};

export default withStyles(useStyles)(Panel);
